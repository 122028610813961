import { useNavigate } from 'react-router-dom';
import OrdersTableToolbar from 'pages/Orders/Orders/components/OrdersToolbar';
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'shared/Tables/table.utils';
import { ORDER_TABS, defaultOrdersFiltersData } from 'utils/constants/orders';
import { OrdersTable } from 'shared/Tables/OrdersTable/OrdersTable';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import { StyledTableTopButton } from 'shared/Tables/table.styles';
import { compileUrl } from 'utils/url';
import { privateRoutesUrls } from 'router/constants';
import { useBackendPagination } from 'hooks/useBackendPagination';
import { useGenerateOrdersCsvFile } from './hooks/useGenerateOrdersCsvFile';
import { useLoadOrders } from './Order/hooks/useLoadOrders';
import { useUrlParams } from 'hooks/useUrlParams';
import { useUserPermissions } from 'store/user/selectors';
import { TitleArea } from 'components/TitleArea/TitleArea';
import { NewCard } from 'components/NewCard/NewCard';
import TableTitleWithActions from 'components/Table/TableTitleWithActions';
import Pagination from 'components/Pagination';
import createOrdersTableTitle from 'pages/Orders/Orders/utils/createOrdersTableTitle';

export const Orders = () => {
  const { urlParams, setUrlParams, setUrlParamsWithDefaults } = useUrlParams(
    defaultOrdersFiltersData,
    privateRoutesUrls.dashboardRoutes.orders
  );
  const { data: ordersData, isLoading: loading } = useLoadOrders(urlParams);
  const paginationResponse = ordersData?.pagination ?? {
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_ITEMS_PER_PAGE,
  };
  const orders = ordersData?.orders ?? [];
  const [generateCSVFile] = useGenerateOrdersCsvFile(urlParams, 'ETP');
  const pagination = useBackendPagination(paginationResponse, setUrlParams);

  const navigate = useNavigate();
  const permissions = useUserPermissions();

  const generateOrdersAsXls = () => {
    generateCSVFile();
  };

  const titleActions = (
    <OrdersTableToolbar
      productType="ETP"
      generateOrdersAsXls={generateOrdersAsXls}
      setUrlParams={setUrlParams}
      urlParams={urlParams}
      disableDownloadButton={!Boolean(orders?.length)}
    />
  );

  const createOwnOrderButton = permissions?.canCreateOrder && (
    <StyledTableTopButton
      onClick={() => navigate(privateRoutesUrls.dashboardRoutes.ordersNew)}
      type="button"
      data-qa-id="createOrderButton"
      size="medium"
      key="toolbar-button"
    >
      <span>Create Order</span>
      <PlusIcon />
    </StyledTableTopButton>
  );

  const delegateOrderButton = permissions?.canCreateOrderDelegated && (
    <StyledTableTopButton
      onClick={() => navigate(privateRoutesUrls.dashboardRoutes.ordersDelegated)}
      type="button"
      data-qa-id="createDelegatedOrderButton"
      size="medium"
      key="toolbar-button"
    >
      <span>New Delegated Order</span>
      <PlusIcon />
    </StyledTableTopButton>
  );

  const createOrderButton = delegateOrderButton || createOwnOrderButton;
  const tabs = ORDER_TABS.map((tab) => ({ name: tab[0], param: tab[1] }));

  return (
    <>
      <TitleArea
        title={'ETP Orders'}
        actionButton={createOrderButton}
        tabConfig={{
          tabs: tabs,
          setUrlParamsWithDefaults: (data) => {
            setUrlParamsWithDefaults(data);
          },
          active: urlParams.tab as string,
        }}
      />
      <NewCard
        header={
          <TableTitleWithActions
            title={createOrdersTableTitle('ETP', urlParams)}
            actions={[titleActions]}
          />
        }
        body={
          <OrdersTable
            productType="ETP"
            orders={orders ?? []}
            loadingData={loading}
            setUrlParams={setUrlParams}
            urlParams={urlParams}
            // TODO: make sure the user has permissions to edit the order, move it to variable
            editAction={(order) => {
              const editOrderUrl = compileUrl(privateRoutesUrls.dashboardRoutes.ordersDetails, {
                label: 'id',
                value: String(order._id!),
              });
              navigate(editOrderUrl);
            }}
            deleteAction={() => {}}
          />
        }
        footer={<Pagination {...pagination} />}
      />
    </>
  );
};

export default Orders;
