import { useNavigate } from 'react-router-dom';
import OrdersTableToolbar from 'pages/Orders/Orders/components/OrdersToolbar';
import { ORDER_TABS, OrderTabType, defaultOrdersFiltersData } from 'utils/constants/orders';
import { OrdersTable } from 'shared/Tables/OrdersTable/OrdersTable';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import { StyledTableTopButton } from 'shared/Tables/table.styles';
import { compileUrl } from 'utils/url';
import { privateRoutesUrls } from 'router/constants';
import { useBackendPagination } from 'hooks/useBackendPagination';
import { useGenerateOrdersCsvFile } from 'pages/Orders/Orders/hooks/useGenerateOrdersCsvFile'; // @TODO TOKENS - do we need csv downloads?
import { useUrlParams } from 'hooks/useUrlParams';
import { useUserPermissions } from 'store/user/selectors';
import { useLoadOrders } from 'pages/Orders/Orders/Order/hooks/useLoadOrders'; // @TODO TOKENS - change out for use "Tokens page" hook
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'shared/Tables/table.utils';
import { TitleArea } from 'components/TitleArea/TitleArea';
import { NewCard } from 'components/NewCard/NewCard';
import TableTitleWithActions from 'components/Table/TableTitleWithActions';
import Pagination from 'components/Pagination';
import createOrdersTableTitle from 'pages/Orders/Orders/utils/createOrdersTableTitle';

export const OrdersTokens = () => {
  const { urlParams, setUrlParams, setUrlParamsWithDefaults } = useUrlParams(
    defaultOrdersFiltersData,
    privateRoutesUrls.dashboardRoutes.ordersTokens
  );
  const { data: ordersData, isLoading: loading } = useLoadOrders(urlParams, 'Token');
  const paginationResponse = ordersData?.pagination ?? {
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_ITEMS_PER_PAGE,
  };
  const orders = ordersData?.orders ?? [];
  const [generateCSVFile] = useGenerateOrdersCsvFile(urlParams, 'Token');
  const pagination = useBackendPagination(paginationResponse, setUrlParams);

  const navigate = useNavigate();
  const permissions = useUserPermissions();

  const generateOrdersAsXls = () => {
    generateCSVFile();
  };

  const titleActions = (
    <OrdersTableToolbar
      productType={'Token'}
      generateOrdersAsXls={generateOrdersAsXls}
      setUrlParams={setUrlParams}
      urlParams={urlParams}
      disableDownloadButton={!Boolean(orders?.length)}
    />
  );

  const createOwnOrderButton = permissions?.canCreateTokenOrder && (
    <StyledTableTopButton
      onClick={() => navigate(privateRoutesUrls.dashboardRoutes.ordersTokensNew)}
      type="button"
      data-qa-id="createOrderButton"
      size="medium"
      key="toolbar-button"
    >
      <span>Create Order</span>
      <PlusIcon />
    </StyledTableTopButton>
  );

  const delegateOrderButton = permissions?.canCreateTokenOrderDelegated && (
    <StyledTableTopButton
      onClick={() => navigate(privateRoutesUrls.dashboardRoutes.ordersTokensDelegated)}
      type="button"
      data-qa-id="createDelegatedOrderButton"
      size="medium"
      key="toolbar-button"
    >
      <span>New Delegated Order</span>
      <PlusIcon />
    </StyledTableTopButton>
  );

  const createOrderButton = delegateOrderButton || createOwnOrderButton;
  const tabs = ORDER_TABS.map((tab) => ({ name: tab[0], param: tab[1] }));
  return (
    <>
      <TitleArea
        title="Token Orders"
        actionButton={createOrderButton}
        tabConfig={{
          tabs: tabs,
          setUrlParamsWithDefaults: (data) => {
            setUrlParamsWithDefaults(data);
          },
          active: urlParams.tab as OrderTabType,
        }}
      />
      <NewCard
        header={
          <TableTitleWithActions
            title={createOrdersTableTitle('Token', urlParams)}
            actions={[titleActions]}
          />
        }
        body={
          <OrdersTable
            productType="Token"
            orders={orders ?? []}
            loadingData={loading}
            setUrlParams={setUrlParams}
            urlParams={urlParams}
            // TODO: make sure the user has permissions to edit the order, move it to variable
            editAction={(order) => {
              const editOrderUrl = compileUrl(
                privateRoutesUrls.dashboardRoutes.ordersTokensDetails,
                {
                  label: 'id',
                  value: String(order._id!),
                }
              );
              navigate(editOrderUrl);
            }}
            deleteAction={() => {}}
          />
        }
        footer={<Pagination {...pagination} />}
      />
    </>
  );
};

export default OrdersTokens;
